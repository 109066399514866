<template>
  <div>
      <Card>
      <template slot="outer">
        <div class="mb-4 flex justify-between align-center">
          <div class="py-3 inline-flex align-center">
            <svg class="w-5" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 32" fill="#5B5B5B">
              <path d="M4 4L4 6L16 6L16 10L14 10L14 20L4 20L4 22L14 22L14 28L28 28L28 26L16 26L16 12L28 12L28 10L18 10L18 4 Z M 9.21875 8.78125L7.78125 10.21875L9.5625 12L4 12L4 14L9.5625 14L7.78125 15.78125L9.21875 17.21875L12.71875 13.71875L13.40625 13L12.71875 12.28125 Z M 22.78125 15L18.59375 19.1875L19.28125 19.90625L22.78125 23.40625L24.21875 22L22.25 20L28 20L28 18L22.625 18L24.21875 16.40625Z" fill="#5B5B5B" />
            </svg>
            <h3 class="pl-2 text-uppercase text-lg">Course Comparison</h3>
          </div>
        </div>
      </template>
      <CardBody class="p-3 relative">
        <div class="mb-4 flex justify-end flex-wrap justify-xs-center">
          <SearchBox
            placeholder="Search"
            class="w-74 p-2 m-1"
            :results="searchResults"
            v-model="keyword"
            result-key="title"
            @on-result="onResult"
            />
        </div>
        <md-divider></md-divider>
        <Table :items="bulkUploads" hover-action>
          <template slot="row" slot-scope="{ item }">
            <md-table-cell md-sort-by="id" md-label="SL" md-numeric>{{ item.id }}</md-table-cell>
            <md-table-cell md-sort-by="qId" md-label="Q_ID">{{ item.qId }}</md-table-cell>
            <md-table-cell md-sort-by="title" md-label="TITLE">
              {{ item.title }}
            </md-table-cell>
            <md-table-cell md-sort-by="section" md-label="SECTION">{{ item.section }}</md-table-cell>
            <md-table-cell md-sort-by="item" md-label="ITEM">{{ item.item }}</md-table-cell>
            <md-table-cell md-sort-by="usedFor" md-label="USED FOR">{{ item.usedFor }}</md-table-cell>
            <md-table-cell md-sort-by="uploadedBy" md-label="UPLOAD BY" class="text-victoria">{{ item.uploadedBy }}</md-table-cell>
            <md-table-cell md-sort-by="uploadedAt" md-label="UPLOADED AT">{{ item.uploadedAt }}</md-table-cell>
            <span class="action">
              <md-icon @click.native="onEdit" class="bg-victoria rounded-full p-1 text-white text-base mr-1">visibility</md-icon>
              <md-icon class="bg-default rounded-full p-1 text-gray-700 text-base">delete</md-icon>
            </span>
          </template>
        </Table>

        <div @click="onShowCreate" style="position: absolute; bottom: 0; right: 0"
          class="bg-victoria pointer center w-24 h-24 rounded-full">
          <md-icon class="text-6xl text-white">add</md-icon>
        </div>
      </CardBody>
    </Card>
    <div class="flex justify-end align-center mt-8">
          <Paginate
            :start="paginate.start"
            :end="paginate.end"
            :total="paginate.total"
            :limit="paginate.limit"
            @on-start="onStart"
            @on-end="onEnd"
          />
      </div>
    <Dialog>
      <component :is="component"></component>
    </Dialog>
  </div>
</template>

<script>
import {
    Card,
    Table,
    Dialog,
    CardBody,
    Paginate,
    SearchBox
    } from "@/components";
import { PromptDetails, BulkUploadModal, PromptEdit } from "@/components/molecule";

import cms from "@/data/cms";
import { bulkUploads } from '@/data/master/qatm/question-management';
import { mapMutations } from "vuex";
import { paginate } from "@/mixins/paginate";
export default {
    mixins: [paginate],
    components: {
        Card,
        Table,
        Dialog,
        Paginate,
        CardBody,
        SearchBox,
        PromptEdit,
        PromptDetails,
        BulkUploadModal
  },
  data() {
      return {
          currentIndex: 0,
          component: 'BulkUploadModal',
          types: cms.types,
          keyword: '',
          selectedKeyword: '',
          query: '?',
          visibility: true,
          pagination: true,
          selectedValue: null,
          itemIndex: null,
          status: '',
          searchParams : new URLSearchParams(),
          paginate: {
            start: 1,
            end: 20,
            total: 100,
            limit: 20
          }
      }
  },
  computed: {
    bulkUploads() {
      if(this.pagination) {
        return bulkUploads.slice(this.paginate.start, this.paginate.end);
      }
      return bulkUploads;
    },
    searchResults() {
        if(this.keyword !== '' && this.keyword !== this.selectedKeyword) {
          return bulkUploads.filter(item => this.toLower(item.title).includes(this.toLower(this.keyword)))
        }
        return [];
      }
  },
   methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onShowDetails() {
      this.component = 'PromptDetails';
      this.dialog(true);
    },
    onShowCreate() {
      this.component = 'BulkUploadModal';
      this.dialog(true);
    },
    onShowEdit() {
      this.component = 'PromptEdit';
      this.dialog(true);
    },
    onResult(value) {
      this.keyword = this.selectedKeyword = value;
      this.onPrepareQueryString('search', this.keyword);
    },
    onStart(value) {
      this.paginate.start -= value;
      this.paginate.end -= value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onEnd(value) {
      this.paginate.start += value;
      this.paginate.end += value;
      this.onPrepareQueryString('offset', this.paginate.start);
      this.onPrepareQueryString('limit', this.paginate.end);
    },
    onPrepareQueryString(key, value) {
      if(this.searchParams.has(key)) {
          this.searchParams.delete(key);
      }
      this.searchParams.append(key, value);
      console.log(this.searchParams.toString());
    },
    toLower(text){
      return text.toString().toLowerCase()
    },
  },
}
</script>